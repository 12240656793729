export var HandlebarHelpersEnum;
(function (HandlebarHelpersEnum) {
    HandlebarHelpersEnum["EQUALS"] = "equals";
    HandlebarHelpersEnum["TITLECASE"] = "titlecase";
    HandlebarHelpersEnum["UPPERCASE"] = "uppercase";
    HandlebarHelpersEnum["LOWERCASE"] = "lowercase";
    HandlebarHelpersEnum["PLURALIZE"] = "pluralize";
    HandlebarHelpersEnum["DATEFORMAT"] = "dateFormat";
})(HandlebarHelpersEnum || (HandlebarHelpersEnum = {}));
export const HandlebarHelpers = {
    [HandlebarHelpersEnum.EQUALS]: { description: 'assert equal' },
    [HandlebarHelpersEnum.TITLECASE]: { description: 'transform to TitleCase' },
    [HandlebarHelpersEnum.UPPERCASE]: { description: 'transform to UPPERCASE' },
    [HandlebarHelpersEnum.LOWERCASE]: { description: 'transform to lowercase' },
    [HandlebarHelpersEnum.PLURALIZE]: { description: 'pluralize if needed' },
    [HandlebarHelpersEnum.DATEFORMAT]: { description: 'format date' },
};
